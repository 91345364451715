import React, { useContext } from "react";
import { FaMobileAlt } from "react-icons/fa";
import { FaEarthAfrica } from "react-icons/fa6";
import {
  MdCloudQueue,
  MdOutlineWeb,
  MdSettingsApplications,
} from "react-icons/md";
import { motion } from "framer-motion";
import { OfferData } from "../assets/data/Offer";
import { LanguageContext } from "../Context/LanguageContext";
import offerImage from "../assets/offerImage.avif";

const Offer = () => {
  const { lang } = useContext(LanguageContext);

  const handleAnimations = (xValue) => {
    let animation = {};
    if (lang === "en") {
      animation = {
        opacity: 0,
        x: -xValue,
      };
    } else {
      animation = {
        opacity: 0,
        x: xValue,
      };
    }
    return animation;
  };
  return (
    <section className="bg-white dark:bg-gray-900" key={lang}>
      <div className="container px-6 py-10 mx-auto">
        <div className="lg:flex lg:items-center">
          <div className="w-full space-y-12 lg:w=1/2">
            <div>
              <motion.h1
                className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white"
                initial={handleAnimations(200)}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: true }}
              >
                {lang === "en" ? "What we Offer" : "ماذا نقدم"}
              </motion.h1>

              <motion.div className="mt-2">
                <motion.span
                  initial={handleAnimations(100)}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.7,
                    },
                  }}
                  viewport={{ once: true }}
                  className="inline-block w-40 h-1 bg-blue-500 rounded-full"
                ></motion.span>
                <motion.span
                  initial={handleAnimations(100)}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.8,
                    },
                  }}
                  className="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"
                ></motion.span>
                <motion.span
                  initial={handleAnimations(100)}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.9,
                    },
                  }}
                  className="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"
                ></motion.span>
              </motion.div>
            </div>

            {OfferData.data.map((item, index) => (
              <motion.div
                key={index}
                initial={handleAnimations(200)}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                className="md:flex md:items-start md:-mx-4"
              >
                <span className="inline-block p-2 text-blue-500 bg-blue-100 rounded-xl md:mx-4 dark:text-white dark:bg-blue-500">
                  {item.icon === "MdSettingsApplications" ? (
                    <MdSettingsApplications className="w-6 h-6" />
                  ) : item.icon === "FaMobileAlt" ? (
                    <FaMobileAlt className="w-6 h-6" />
                  ) : item.icon === "FaEarthAfrica" ? (
                    <FaEarthAfrica className="w-6 h-6" />
                  ) : item.icon === "MdOutlineWeb" ? (
                    <MdOutlineWeb className="w-6 h-6" />
                  ) : item.icon === "MdCloudQueue" ? (
                    <MdCloudQueue className="w-6 h-6" />
                  ) : null}
                </span>

                <div className="mt-4 md:mx-4 md:mt-0">
                  <h1 className="text-xl font-semibold text-gray-700 capitalize dark:text-white">
                    {lang === "en" ? item.title_en : item.title_ar}
                  </h1>

                  <p className="mt-3 text-gray-500 dark:text-gray-300">
                    {lang === "en"
                      ? item.description_en
                          .split(" ")
                          .reduce((acc, word, index) => {
                            const lineNumber = Math.floor(index / 9);
                            if (!acc[lineNumber]) acc[lineNumber] = [];
                            acc[lineNumber].push(word);
                            return acc;
                          }, [])
                          .map((line, index) => (
                            <span key={index}>
                              {line.join(" ")}
                              <br />
                            </span>
                          ))
                      : item.description_ar
                          .split(" ")
                          .reduce((acc, word, index) => {
                            const lineNumber = Math.floor(index / 9);
                            if (!acc[lineNumber]) acc[lineNumber] = [];
                            acc[lineNumber].push(word);
                            return acc;
                          }, [])
                          .map((line, index) => (
                            <span key={index}>
                              {line.join(" ")}
                              <br />
                            </span>
                          ))}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>

          <motion.div
            className="hidden lg:flex lg:items-center lg:w-full lg:justify-center"
            initial={handleAnimations(-200)}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: {
                duration: 2,
              },
            }}
            viewport={{ once: true }}
          >
            <img
              className="w-[28rem] h-[28rem] object-cover xl:w-[34rem] xl:h-[34rem] rounded-full"
              src={offerImage}
              alt="what we offer"
            />
          </motion.div>
        </div>

        <hr className="my-12 border-gray-200 dark:border-gray-700" />

        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
          {/* Placeholder for grid items */}
          {/* Ideally, these would be rendered dynamically */}
        </div>
      </div>
    </section>
  );
};

export default Offer;
