import React, { createContext, useState, useEffect } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState(localStorage.getItem("lang") || "ar");
  console.log(lang);
  useEffect(() => {
    localStorage.setItem("lang", lang);

    const style = document.body.style;
    if (lang === "ar") {
      console.log('ar here')
      style.fontFamily = '"Cairo", sans-serif';
      style.fontOpticalSizing = "auto";
      style.fontWeight = 417;
      style.fontStyle = "normal";
      style.fontVariationSettings = '"slnt" 0';
    } else {
      console.log('en here')
      style.fontFamily = '"PT Serif", serif';
      style.fontWeight = 400;
      style.fontStyle = "normal";
      // Reset any previously set properties
      style.fontOpticalSizing = "";
      style.fontVariationSettings = "";
    }
  }, [lang]);

  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
      {children}
    </LanguageContext.Provider>
  );
};
