import React, { useContext, useState } from "react";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "mdi/css/materialdesignicons.min.css";
import { LanguageContext } from "../Context/LanguageContext";

const TranslationSwitcher = ({ click }) => {
  const { lang, setLang } = useContext(LanguageContext);
  const [menuToggle, setMenuToggle] = useState(false);

  const countries = [
    { label: "English", lang: "en", flag: "gb" },
    { label: "Arabic", lang: "ar", flag: "ae" },
  ];

  const handleClickAway = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setMenuToggle(false);
    }
  };

  const selected = countries.findIndex((country) => country.lang === lang);

  return (
    <div className="w-full md:w-1/2 lg:w-28 mr-4 mt-4 ml-[-20px] dark:bg-gray-900">
      <div className="w-full flex justify-end">
        <div className="relative pb-5" onBlur={handleClickAway}>
          <button
            className="bg-white text-gray-500 rounded shadow-lg py-2 pr-3 pl-5 focus:outline-none dark:bg-blue-600 dark:text-white"
            onClick={() => setMenuToggle(!menuToggle)}
          >
            <span className={`fi w-6 fi-${countries[selected].flag}`}></span>{" "}
            <i className="mdi mdi-chevron-down"></i>
          </button>
          {menuToggle && (
            <div className="bg-white text-gray-700 shadow-md rounded text-sm absolute mt-12 top-0 right-0 min-w-full w-48 z-30">
              <span className="absolute top-0 right-0 w-3 h-3 bg-white transform rotate-45 -mt-1 mr-3 dark:bg-gray-900"></span>
              <div className="bg-white overflow-auto rounded w-full relative z-10">
                <ul className="list-reset dark:bg-gray-500">
                  {countries.map((item, index) => (
                    <li key={index}>
                      <a
                        href="#"
                        className="px-4 py-2 flex hover:bg-gray-100 no-underline hover:no-underline transition-colors duration-100"
                        onClick={(e) => {
                          e.preventDefault();
                          setLang(item.lang);
                          setMenuToggle(false);
                          click(false);
                        }}
                      >
                        <span
                          className={`inline-block mr-2 flag-icon flag-icon-${item.flag}`}
                        ></span>
                        <span className="inline-block">{item.label}</span>
                        {index === selected && (
                          <span className="ml-auto">
                            <i className="mdi mdi-check"></i>
                          </span>
                        )}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TranslationSwitcher;
