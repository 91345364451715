import React, { useContext } from "react";
import aboutImage from "../assets/about.png";
import { LanguageContext } from "../Context/LanguageContext";
import { motion } from "framer-motion";

const About = () => {
  const { lang } = useContext(LanguageContext);
  const handleAnimations = (xValue) => {
    let animation = {};
    if (lang === "en") {
      animation = {
        opacity: 0,
        x: -xValue,
      };
    } else {
      animation = {
        opacity: 0,
        x: xValue,
      };
    }
    return animation;
  };
  return (
    <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
      <div className="flex flex-col lg:flex-row justify-between gap-8 mb-[200px]">
        <motion.div
          className="w-full lg:w-5/12 flex flex-col justify-center"
          initial={handleAnimations(200)}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 2,
            },
          }}
          viewport={{ once: true }}
        >
          <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-black pb-4 mb-6 dark:text-white">
            {lang === "en" ? "Who are we" : "من نحن"}
          </h1>
          <p className="font-medium text-base leading-8 text-gray-500 dark:text-white">
            {lang === "en"
              ? "At Seek Higher Things, we are dedicated to delivering innovative and high-quality software development solutions tailored to meet the unique needs of our clients. Our team of skilled developers, designers, and project managers work collaboratively to create cutting-edge software that drives business success and elevates user experiences."
              : "في Seek Higher Things، نحن ملتزمون بتقديم حلول تطوير برمجيات مبتكرة وعالية الجودة مصممة لتلبية الاحتياجات الفريدة لعملائنا. يعمل فريقنا من المطورين والمصممين ومديري المشاريع المهرة بشكل تعاوني لإنشاء برامج متطورة تقود نجاح الأعمال وترفع من تجارب المستخدم."}
          </p>
        </motion.div>
        <motion.div
          className="w-full lg:w-8/12"
          initial={handleAnimations(-200)}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 2,
            },
          }}
          viewport={{ once: true }}
        >
          <img
            className="w-full h-full rounded-[80px]"
            src={aboutImage}
            alt="A group of People"
          />
        </motion.div>
      </div>
      <hr className="my-12 border-gray-200 dark:border-gray-700" />
    </div>
  );
};

export default About;
