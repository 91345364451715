// NOTE: This scroll to top is the actual working scroll to to when user clicks on the circle arrow that appears when use scrolls down.
// The other `ScrollToTop` component in components folder is for the default react scroll to top behavior on route visit.

import { useState, useEffect, useContext } from "react";
import { FiChevronUp } from "react-icons/fi";
import { LanguageContext } from "../Context/LanguageContext";

const useScrollToTop = () => {
  const { lang } = useContext(LanguageContext);
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", scrollToTop);
    return function cleanup() {
      window.removeEventListener("scroll", scrollToTop);
    };
  });

  const scrollToTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", scrollToTop);
  const handleDir = () => {
    if(lang === 'en'){
        return {
            height: 55,
            width: 55,
            borderRadius: 50,
            right: 50,
            bottom: 50,
            display: showScroll ? "flex" : "none",
            padding: 5,
          };
    } else {
        return {
            height: 55,
            width: 55,
            borderRadius: 50,
            left: 0,
            bottom: 50,
            display: showScroll ? "flex" : "none",
            padding: 5,
            marginLeft: 25
          }
    }
  }
  return (
    <>
      <FiChevronUp
        className="scrollToTop -mr-6"
        onClick={backToTop}
        style={handleDir()}
      />
    </>
  );
};

export default useScrollToTop;
