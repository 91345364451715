import React, { lazy } from "react";
import { motion } from "framer-motion";
import Location from "./Location";
const MainContent = lazy(() => import("./MainContent"));
const Offer = lazy(() => import("./Offer"));
const About = lazy(() => import("./About"));
const Contact = lazy(() => import("./Contact"));

const Home = () => {
  return (
    <motion.div
      className=""
      initial={{ x: "-100%" }}
      whileInView={{ x: 0, transition: { duration: 1 } }}
    >
      {/* <Location /> */}
      <MainContent />
      <Offer />
      {/* <Portfolio /> */}
      <About />
      <Contact />
    </motion.div>
  );
};

export default Home;
