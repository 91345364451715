import React, { useContext, useEffect, useRef, useState } from "react";
import { LanguageContext } from "../Context/LanguageContext";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { ContactData } from "../assets/data/Contact";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { InfinitySpin } from "react-loader-spinner";
import { ReactTyped } from "react-typed";

function Contact() {
  const { lang } = useContext(LanguageContext);
  const form = useRef();

  const [loading, setLoading] = useState(false);

  const handleAnimations = (xValue) => {
    let animation = {};
    if (lang === "en") {
      animation = {
        opacity: 0,
        x: -xValue,
      };
    } else {
      animation = {
        opacity: 0,
        x: xValue,
      };
    }
    return animation;
  };

  const sendEmail = (e) => {
    setLoading(true);
    e.preventDefault();

    emailjs
      .sendForm("service_ahlo76e", "template_zi60r7t", form.current, {
        publicKey: "BvbLQiNE1p21wdxMf",
      })
      .then(
        (res) => {
          if (res.status === 200 && res.text === "OK") {
            setLoading(false);
            Swal.fire({
              position: "top-right",
              icon: "success",
              title: lang === 'en' ? "Thank you for contacting with us we'll respond shortly." : "نشكرك على تواصلك معنا وسنرد عليك قريبًا",
              showConfirmButton: false,
              timer: 2500,
              toast: true,
            });
          }
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      {loading ? (
        <div className="flex justify-center items-center w-[100%] h-screen">
          <div>
            <InfinitySpin
              visible={true}
              color="blue"
              ariaLabel="infinity-spin-loading"
            />
            <h1 className="text-center">
              { lang === 'en' ? "Sending your request " : "جارٍ إرسال طلبك " }
              <ReactTyped
                className="text-center text-2xl md:text-xl sm:text-lg"
                strings={["....."]}
                typeSpeed={120}
                backSpeed={140}
                loop
              />
            </h1>
          </div>
        </div>
      ) : (
        <div className="container px-6 py-12 mx-auto">
          <div className="lg:flex lg:items-center lg:-mx-6">
            <motion.div className="lg:w-1/2 lg:mx-6">
              <motion.h1
                className="text-2xl font-semibold text-gray-800 capitalize dark:text-white lg:text-3xl"
                initial={handleAnimations(200)}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 2,
                  },
                }}
                viewport={{ once: true }}
              >
                {lang === "en" ? "Contact us for" : "تواصل معنا "}
              </motion.h1>
              <motion.h2
                className="text-xl font-semibold text-gray-800 capitalize dark:text-white lg:text-2xl"
                initial={handleAnimations(200)}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 2,
                  },
                }}
                viewport={{ once: true }}
              >
                {lang === "en" ? "more info" : "للمزيد من المعلومات"}
              </motion.h2>
              <motion.div className="mt-6 space-y-8 md:mt-8">
                {/* <p className="flex items-start -mx-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 mx-2 text-blue-500 dark:text-blue-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>

                <span className="mx-2 text-gray-700 truncate w-72 dark:text-gray-400">
                  Cecilia Chapman 711-2880 Nulla St. Mankato Mississippi 96522
                </span>
              </p> */}

                <motion.p
                  className="flex items-start -mx-2"
                  initial={handleAnimations(300)}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 2,
                    },
                  }}
                  viewport={{ once: true }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mx-2 text-blue-500 dark:text-blue-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>

                  <span
                    className="mx-2 text-gray-700 truncate w-72 dark:text-white"
                    style={
                      lang === "en"
                        ? {
                            direction: "ltr",
                            textAlign: "left",
                          }
                        : {
                            direction: "ltr",
                            textAlign: "right",
                          }
                    }
                  >
                    {/* (+963) 931-443-885 */}
                    {ContactData.data.number}
                  </span>
                </motion.p>

                <motion.p
                  className="flex items-start -mx-2"
                  initial={handleAnimations(300)}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 2,
                    },
                  }}
                  viewport={{ once: true }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mx-2 text-blue-500 dark:text-blue-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>

                  <span className="mx-2 text-gray-700 truncate w-72 dark:text-white">
                    {ContactData.data.email}
                  </span>
                </motion.p>
              </motion.div>

              <motion.div className="mt-6 w-80 md:mt-8">
                <motion.h3
                  className="text-gray-600 dark:text-white"
                  initial={handleAnimations(300)}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 2,
                    },
                  }}
                  viewport={{ once: true }}
                >
                  {lang === "en" ? "Follow us" : "تابعنا"}
                </motion.h3>

                <motion.div
                  className="flex mt-4 -mx-1.5 "
                  initial={handleAnimations(300)}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 2,
                    },
                  }}
                  viewport={{ once: true }}
                >
                  <motion.a
                    className="mx-1.5 dark:hover:text-blue-400 dark:text-white text-gray-400 transition-colors duration-300 transform hover:text-blue-500"
                    href={ContactData.data.linkedIn}
                    whileTap={{ scale: 0.85 }}
                  >
                    <FaLinkedin className="w-8 h-8" />
                  </motion.a>

                  <motion.a
                    className="mx-1.5 dark:hover:text-blue-400 dark:text-white text-gray-400 transition-colors duration-300 transform hover:text-blue-500"
                    href={ContactData.data.instagram}
                    whileTap={{ scale: 0.85 }}
                  >
                    <FaInstagram className="w-8 h-8" />
                  </motion.a>

                  <motion.a
                    className="mx-1.5 dark:hover:text-blue-400 dark:text-white text-gray-400 transition-colors duration-300 transform hover:text-blue-500"
                    href={ContactData.data.facebook}
                    whileTap={{ scale: 0.85 }}
                  >
                    <FaFacebook className="w-8 h-8" />
                  </motion.a>
                </motion.div>
              </motion.div>
            </motion.div>

            <motion.div
              className="mt-8 lg:w-1/2 lg:mx-6"
              initial={handleAnimations(-200)}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 2,
                },
              }}
              viewport={{ once: true }}
            >
              <motion.div className="w-full px-8 py-10 mx-auto overflow-hidden bg-white rounded-lg shadow-2xl dark:bg-gray-900 lg:max-w-xl shadow-blue-900/75 dark:shadow-black/50">
                <motion.h1
                  className="text-lg font-medium text-gray-700 dark:text-white"
                  initial={handleAnimations(-200)}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 2,
                    },
                  }}
                  viewport={{ once: true }}
                >
                  {lang === "en"
                    ? "What do you want to ask"
                    : "ماذا تريد أن تسأل"}
                </motion.h1>

                <form ref={form} onSubmit={sendEmail} className="mt-6">
                  <div className="flex-1">
                    <label className="block mb-2 text-sm text-gray-600 dark:text-white">
                      {lang === "en" ? "Full Name" : "الاسم الكامل"}
                    </label>
                    <input
                      type="text"
                      placeholder="Full Name"
                      name="name"
                      className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 dark:placeholder-white bg-white border border-gray-200 rounded-md dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>

                  <div className="flex-1 mt-6">
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                      {lang === "en" ? "Email address" : "البريد الإلكتروني"}
                    </label>
                    <input
                      type="email"
                      placeholder="example@example.com"
                      name="email"
                      className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-white dark:bg-gray-900 dark:text-white dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>

                  <div className="w-full mt-6">
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                      {lang === "en" ? "Message" : "رسالة"}
                    </label>
                    <textarea
                      name="message"
                      className="block w-full h-32 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md md:h-48 dark:placeholder-white dark:bg-gray-900 dark:text-white dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <input hidden name="date" value={new Date().getFullYear()} />

                  <motion.button
                    whileTap={{ scale: 0.85 }}
                    className="w-full px-6 py-3 mt-6 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                  >
                    {lang === "en" ? "get in touch" : "ابقى على تواصل"}
                  </motion.button>
                </form>
              </motion.div>
            </motion.div>
          </div>
          <hr className="my-12 border-gray-200 dark:border-gray-700" />
        </div>
      )}
    </section>
  );
}

export default Contact;
