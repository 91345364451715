import { Route, Router, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Offer from "./Components/Offer";
import Home from "./Components/Home";
import About from "./Components/About";
import { LanguageProvider } from "./Context/LanguageContext";
import Contact from "./Components/Contact";
import { Suspense, useEffect, useState } from "react";
import PreLoader from "./Components/PreLoader";
import ScrollToTop from "./Components/ScrollToTop";
import UseScrollToTop from "./hooks/useScrollToTop";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000); // 1 second loading time

    document.addEventListener("gesturestart", function (e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document.addEventListener("gesturechange", function (e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document.addEventListener("gestureend", function (e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });
  }, []);

  const appContent = () => {
    return (
      <div className="dark:bg-[#212121]">
        <LanguageProvider>
          <ScrollToTop />
          <Navbar />
          <Suspense fallback={""}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/services" element={<Offer />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </Suspense>
          <Footer />
          <UseScrollToTop />
        </LanguageProvider>
      </div>
    );
  };

  return (
    <div>{loading ? <PreLoader setLoading={setLoading} /> : appContent()}</div>
  );
}

export default App;
