import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import animationData from "../assets/pre-loader.json";
import { motion, AnimatePresence } from "framer-motion";
import logo from "../assets/logo.png";

const PreLoader = ({ setLoading }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const animationInstance = lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
    });

    animationInstance.addEventListener("DOMLoaded", () => {
      const svg = containerRef.current.querySelector("svg");
      if (svg) {
        const paths = svg.querySelectorAll("path");
        paths.forEach((path) => {
          path.setAttribute("stroke", "blue"); // Change stroke color to blue
          path.setAttribute("fill", "blue"); // Change fill color to blue
        });
      }
    });

    // Set loading to false after animation duration
    const animationDuration = 3000; // 3 seconds for demonstration
    const timer = setTimeout(() => {
      setLoading(false);
    }, animationDuration);

    return () => {
      clearTimeout(timer);
      animationInstance.destroy();
    };
  }, [setLoading]);

  return (
    <AnimatePresence>
      <motion.div
        key="preloader"
        initial={{ opacity: 1 }}
        whileInView={{ opacity: 0 }}
        transition={{ duration: 3 }}
        className="flex flex-col items-center justify-center h-screen bg-gray-100"
      >
        <img className="w-40 h-40" src={logo} alt="logo" />
        <div
          className="flex items-center justify-center h-1/2 w-1/2 sm:h-3/5 sm:w-3/5 md:h-2/3 md:w-2/3 lg:h-1/2 lg:w-1/2 xl:h-1/3 xl:w-1/3"
          ref={containerRef}
        ></div>
        <motion.h1
          className="text-3xl font-bold text-center "
          style={{
            fontFamily: "PT Serif",
            fontWeight: 500,
            fontStyle: "normal",
          }}
        >
          Welcome To <br /> Seek Higher Things
        </motion.h1>
      </motion.div>
    </AnimatePresence>
  );
};

export default PreLoader;
