import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import TranslationSwitcher from "./TranslationSwitcher";
import { LanguageContext } from "../Context/LanguageContext";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { ContactData } from "../assets/data/Contact";

const Footer = () => {
  const [mode, setMode] = useState("auto");
  const { lang } = useContext(LanguageContext);
  const handleYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer className="bg-white dark:bg-gray-900">
      <div className="container px-6 py-8 mx-auto">
        <div className="flex flex-col items-center text-center">
          <Link to="/">
            <img className="w-full h-full" src={logo} alt="Logo" />
            <TranslationSwitcher />
          </Link>
          <div className="flex flex-wrap justify-center mt-6 -mx-4">
            <Link
              to="/"
              className="mx-4 text-sm text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
              aria-label="Home"
            >
              {lang === "en" ? "Home" : "الرئيسية"}
            </Link>
            <Link
              to="/services"
              className="mx-4 text-sm text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
              aria-label="Our Services"
            >
              {lang === "en" ? "Our Services" : "خدماتنا"}
            </Link>
            <Link
              to="/contact"
              className="mx-4 text-sm text-gray-600 transition-colors duration-300 hover:text-blue-500 dark:text-gray-300 dark:hover:text-blue-400"
              aria-label="Contact"
            >
              {lang === "en" ? "Contact" : "تواصل معنا"}
            </Link>
          </div>
        </div>

        <hr className="my-6 border-gray-200 md:my-10 dark:border-gray-700" />

        <div className="flex flex-col items-center sm:flex-row sm:justify-between">
          <p className="text-sm text-gray-500 dark:text-gray-300">
            { lang === 'en' ? `© ${handleYear()} All Rights Reserved For Seek Higher Things.` :
             `© ${handleYear()} جميع الحقوق محفوظة ل Seek Higher Things.` }
          </p>
          <div className="flex -mx-2 sm:mt-4 md:mt-4 mt-2">
            <Link
              to={ContactData.data.linkedIn}
              className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
              aria-label="LinkedIn"
            >
              <FaLinkedin className="w-5 h-5 fill-current" />
            </Link>

            <Link
              to={ContactData.data.facebook}
              className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
              aria-label="Facebook"
            >
              <FaFacebook className="w-5 h-5 fill-current" />
            </Link>

            <Link
              to={ContactData.data.instagram}
              className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
              aria-label="Github"
            >
              <FaInstagram className="w-5 h-5 fill-current" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
