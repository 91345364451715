import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";
import { motion } from "framer-motion";
import TranslationSwitcher from "./TranslationSwitcher";
import { LanguageContext } from "../Context/LanguageContext";
import { IoMoon } from "react-icons/io5";
import { IoSunny } from "react-icons/io5";
import { FiMoon, FiSun } from "react-icons/fi";

const Navbar = () => {
  const { lang } = useContext(LanguageContext);
  const [nav, setNav] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [dark, setDark] = useState(true);

  const darkModeHandler = () => {
    setDark(!dark);
    document.body.classList.toggle("dark");
  };
  const handleNav = () => {
    setNav(!nav);
  };

  const location = useLocation();
  const { pathname } = location;
  const isRTL = lang === "ar";

  // Define the active class
  const activeClass =
    "bg-[#dfeefa] rounded-[50px] text-[#3066be] text-lg shadow-lg";

  useEffect(() => {
    console.log(document.getElementsByTagName("html"));
    isRTL
      ? document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
      : document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
  }, [isRTL]);

  return (
    <nav
      className={`container p-6 mx-auto lg:flex lg:justify-between lg:items-center ${
        isRTL ? "text-right" : "text-left"
      }`}
      key={lang}
    >
      <div className="flex items-center justify-between">
        <Link to="/">
          <img
            className="w-60 md:h-24 sm:h-24 max-h-24"
            src={logo}
            alt="Logo"
          />
        </Link>
        {/* Mobile menu button */}
        <div className="flex lg:hidden">
          <motion.button
            onClick={() => setIsOpen(!isOpen)}
            whileTap={{ scale: 0.85 }}
            type="button"
            className="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
            aria-label="toggle menu"
          >
            {!isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 8h16M4 16h16"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            )}
          </motion.button>
        </div>
      </div>

      {/* Mobile Menu */}
      <motion.div
        className={`absolute inset-x-0 z-20 w-full px-6 py-4 text-center transition-all duration-300 ease-in-out bg-white shadow-md lg:bg-transparent lg:dark:bg-transparent lg:shadow-none dark:bg-gray-900 lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center ${
          isOpen ? "translate-x-0 opacity-100" : "opacity-0 -translate-x-full"
        }`}
      >
        <div
          className={`flex flex-col space-y-4 lg:mt-0 lg:flex-row lg:space-y-0 ${
            isRTL ? "lg:space-x-reverse lg:space-x-8" : "lg:space-x-8"
          }`}
        >
          <motion.div
            whileTap={{ scale: 0.85 }}
            className="text-gray-700 transition-colors duration-300 transform dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"
          >
            <Link to="/" onClick={() => setIsOpen(false)}>
              {lang === "en" ? "Home" : "الرئيسية"}
            </Link>
          </motion.div>
          <motion.div
            whileTap={{ scale: 0.85 }}
            className="text-gray-700 transition-colors duration-300 transform dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"
          >
            <Link to="/services" onClick={() => setIsOpen(false)}>
              {lang === "en" ? "Our Services" : "خدماتنا"}
            </Link>
          </motion.div>
          {/* <motion.div
            whileTap={{ scale: 0.85 }}
            className="text-gray-700 transition-colors duration-300 transform dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"
          >
            <Link to="/portfolio">
              {lang === "en" ? "Portfolio" : "معرض اعمالنا"}
            </Link>
          </motion.div> */}
          <motion.div
            whileTap={{ scale: 0.85 }}
            className="text-gray-700 transition-colors duration-300 transform dark:text-gray-200 dark:hover:text-blue-400 hover:text-blue-500"
          >
            <Link to="/contact" onClick={() => setIsOpen(false)}>
              {lang === "en" ? "Contact" : "تواصل معنا"}
            </Link>
          </motion.div>
        </div>

        <div className="md:w-1/2 lg:w-28 w-2">
          <button
            className={`w-10 h-10 rounded-[180px] bg-blue-600 p-2 ${
              lang === "en" ? "ml-6 mr-6" : "mr-4 -ml-4"
            }`}
            onClick={() => darkModeHandler()}
          >
            {dark && <FiSun size={"small"} />}
            {!dark && <FiMoon size={"small"} />}
          </button>
        </div>

        <div
          className={`flex justify-center items-center lg:mt-0 lg:w-auto ${
            isRTL ? "ml-20" : ""
          }`}
        >
          <TranslationSwitcher click={setIsOpen} />
        </div>
        <motion.div
          onClick={() => setIsOpen(false)}
          whileTap={{ scale: 0.85 }}
          className="block px-5 py-2 mt-4 text-sm text-center text-white capitalize bg-blue-600 rounded-lg lg:mt-0 hover:bg-blue-500 lg:w-auto"
        >
          <Link to="/services" className="text-white">
            {lang === "en" ? "Get started" : "البدء"}
          </Link>
        </motion.div>
      </motion.div>
    </nav>
  );
};

export default Navbar;
