export const OfferData = {
  data: [
    {
      id: 1,
      title_en: "Custom Software Development:",
      title_ar: "تطوير البرمجيات المخصصة:",
      icon: "MdSettingsApplications",
      description_en:
        "We specialize in developing bespoke software solutions that align perfectly with your business goals and operational needs.",
      description_ar:
        "نحن متخصصون في تطوير حلول برمجية مخصصة تتوافق تمامًا مع أهداف عملك واحتياجاتك التشغيلية.",
    },
    {
      id: 2,
      title_en: "Mobile App Development:",
      title_ar: "تطوير تطبيقات الهاتف المحمول:",
      icon: "FaMobileAlt",
      description_en:
        "Our experts craft intuitive and engaging mobile applications for both iOS and Android platforms, ensuring a seamless user experience.",
      description_ar:
        "يقوم خبراؤنا بتصميم تطبيقات جوال بديهية وجذابة لكل من منصات iOS وAndroid، مما يضمن تجربة مستخدم سلسة.",
    },
    {
      id: 3,
      title_en: "Web Development:",
      title_ar: "تطوير المواقع:",
      icon: "FaEarthAfrica",
      description_en:
        "From dynamic websites to complex web applications, we build responsive and interactive solutions that captivate your audience.",
      description_ar:
        "من مواقع الويب الديناميكية إلى تطبيقات الويب المعقدة، نقوم ببناء حلول سريعة الاستجابة وتفاعلية تأسر جمهورك.",
    },
    {
      id: 4,
      title_en: "UI/UX Design:",
      title_ar: "تصميم واجهة المستخدم/تجربة المستخدم:",
      icon: "MdOutlineWeb",
      description_en:
        "Our design team creates visually stunning and user-friendly interfaces that enhance usability and engagement.",
      description_ar:
        "يقوم فريق التصميم لدينا بإنشاء واجهات مذهلة بصريًا وسهلة الاستخدام تعمل على تحسين سهولة الاستخدام والمشاركة.",
    },
    {
      id: 5,
      title_en: "Cloud Solutions:",
      title_ar: "الحلول السحابية:",
      icon: "MdCloudQueue",
      description_en:
        "We provide secure and scalable cloud-based solutions to help you leverage the power of cloud computing for your business.",
      description_ar:
        "نحن نقدم حلولاً سحابية آمنة وقابلة للتطوير لمساعدتك على الاستفادة من قوة الحوسبة السحابية لعملك.",
    },
  ],
};
